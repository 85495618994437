import React, { useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';

const QrPost = (props) => {
    const { type, item } = props;

    switch (type) {
        case 1:
            return (
                <Card className="qr-post __type1">
                    <Card.Img variant="top" src="" className="qr-post-image" />
                    <Card.Body>
                        <p className="qr-post-date">4 horas atrás</p>
                        <Card.Title>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Card.Title>
                        <Card.Text>
                            This is a longer card with supporting text below as a natural
                            lead-in to additional content. This content is a little bit
                            longer.
                        </Card.Text>
                    </Card.Body>
                </Card>
            )
        case 2:
            return (
                <Card className="qr-post __type2">
                    <Row>
                        <Col xs={6}>
                            <Card.Img variant="top" src="" className="qr-post-image" />
                        </Col>
                        <Col xs={6}>
                            <Card.Body>
                                <p className="qr-post-date">4 horas atrás</p>
                                <Card.Title>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Card.Title>
                                <Card.Text>
                                    This is a longer card with supporting text below as a natural
                                    lead-in to additional content. This content is a little bit
                                    longer.
                                </Card.Text>
                            </Card.Body>
                        </Col>
                    </Row>
                </Card>
            )
        default:
            return "Selecione um tipo [type=1 ou 2]"
    }
}

export default QrPost;