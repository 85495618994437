import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap';

import QrNavbar from "../components/QrNavbar";
import QrPost from '../components/QrPost';
import { QrSEO } from "../components/QrHead";

const Blog = () => {
    return (
        <div id="qr-wrap">
            <QrSEO title="Qranio - Blog" pageName="Blog" pageClass="Blog" />
            <QrNavbar />
            <div id="blog">
                <Container>
                    <Row>
                        <Col xs={12} className="mb-4">
                            <QrPost type={2} />
                        </Col>
                        <Col xs={12} md={6} lg={3} className="mb-3">
                            <QrPost type={1} />
                        </Col>
                        <Col xs={12} md={6} lg={3} className="mb-3">
                            <QrPost type={1} />
                        </Col>
                        <Col xs={12} md={6} lg={3} className="mb-3">
                            <QrPost type={1} />
                        </Col>
                        <Col xs={12} md={6} lg={3} className="mb-3">
                            <QrPost type={1} />
                        </Col>
                        <Col xs={12} md={6} lg={3} className="mb-3">
                            <QrPost type={1} />
                        </Col>
                        <Col xs={12} md={6} lg={3} className="mb-3">
                            <QrPost type={1} />
                        </Col>
                        <Col xs={12} md={6} lg={3} className="mb-3">
                            <QrPost type={1} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Blog
